//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import '@webcomponents/custom-elements';

window.$ = $;

import 'bootstrap';

import { sk } from './sk';
window.sk = sk;  // HACK

import * as moment from 'moment';
import 'moment/locale/el';

import * as browser from './browser';
import * as tracking from './tracking';
import * as utils from './utils';

import './elements/sk-address-delete-button';
import './elements/sk-payment-method-delete-button';
import './elements/sk-address-list';
import './elements/sk-address-selector';
import './elements/sk-catalog-entry';
import './cart/main';
import './elements/sk-cart-coupon-delete';
import './elements/sk-cart-empty';
import './elements/sk-cart-entry';
import './elements/sk-cart-entry-delete';
import './elements/sk-cart-entry-quantity-add';
import './elements/sk-cart-payment-method-form';
import './elements/sk-order-list';
import './elements/sk-payment-method-list';
import './elements/sk-slot-selector';

import './modals/forgot-password';
import './modals/new-address-search';
import './modals/new-address';
import './modals/new-phone-number';
import './modals/postal-code-subscription';
import './modals/sign-in';
import './modals/sign-up';
import './modals/verify-phone-number';

$(function () {
  if (browser.isFirefox) {
    document.body.dataset.browser = 'firefox';
  } else if (browser.isMSIE) {
    document.body.dataset.browser = 'ie';
  } else if (browser.isEdge) {
    document.body.dataset.browser = 'edge';
  }

  $('a.logout-link').on('click', function (e) {
    e.preventDefault();

    sk.post('/api/auth/logout/').then((response) => {
      if (response.status >= 400) {
        alert('Η αποσύνδεση απέτυχε 😞! Επικοινωνήστε με το support@sparklean.gr.');
      } else {
        window.location = '/';
      }
    }).catch(() => {
      alert('Δεν μπορέσαμε να σας αποσυνδέσουμε! Επικοινωνήστε με το support@sparklean.gr.');
    });
  });

  // Script for landing page.
  $('.check-postal-code-form').on('submit', function (e) {
    e.preventDefault();
    e.stopPropagation();

    let postalCode = $(this).find('.postal-code').val();

    if (!postalCode) {
      window.location = '/products';
    } else {
      sk.get(`/api/postal-codes/${postalCode}/`).then((response) => {
        if (response.ok) {
          window.location = '/products/';
        } else if (response.status == 404) {
          $('#postal-code-subscription-modal form').attr('action', `/api/postal-codes/${postalCode}/`);
          $('#postal-code-subscription-modal').modal('show');

          setTimeout(() => {
            $('#postal-code-subscription-modal input[name="email"]').focus();
          }, 500);
        } else {
          alert('Κάτι πήγε στραβά!');
        }
      });
    }
  });

  // Script for checkout pages

  // Stage 2: Choose pickup and delivery time slots
  $('.go-to-checkout-confirmation').on('click', (e) => {
    let timeSlotsForm = document.querySelector('.time-slots-form');

    if (!timeSlotsForm.checkValidity()) {
      timeSlotsForm.reportValidity();
      e.preventDefault();
      e.stopPropagation();
    }
  });

  // Stage 3: Confirm
  $('.slot-date').each((i, el) => {
    let originalDate = el.textContent.trim();

    if (originalDate == '-') {
      return;
    }

    let momentDate = moment(originalDate, 'YYYY-M-D');

    moment.locale('el');
    el.textContent = momentDate.format('dddd D MMMM');
  });

  // Script for profile page
  $('.profile-form .change-password .btn').on('click', (e) => {
    let $this = $('.profile-form .change-password .btn');
    let payload = new FormData();
    payload.append('email', $('#email').val());

    sk.post($this.data('url'), payload)
      .then(function () {
        alert('Τέλεια! Έλεγξε το email σου για τις οδηγίες επαναφοράς κωδικού.');
        $this.removeAttr('disabled');
      })
      .catch(function () {
        alert('Ουπς! Κάτι πήγε στραβά. Δοκίμασε να κάνεις refresh τη σελίδα.');
        $this.removeAttr('disabled');
      });

    $this.attr('disabled', 'disabled');
  });

  $('.go-to-checkout').on('click', (e) => {
    if (!document.body.dataset.user) {
      e.preventDefault();
      $('#sign-in-modal').modal('show');
      $('.sign-in-next').val(e.target.href);
    }
  });

  $('#submit-order').on('click', function (e) {
    const paymentMethod = document.querySelector('#cart-payment-method-form input[name=payment_method]:checked');

    if (!paymentMethod) {
      alert('Επέλεξε μία μέθοδο πληρωμής πρώτα, για να ολοκληρώσεις την παραγγελία σου!');
      return;
    }

    const button = this;
    button.disabled = true;

    sk.get('/api/orders/cart/validate/').then(async function (response) {
      const token = paymentMethod.value;
      const vendor = paymentMethod.getAttribute('data-vendor')
      if (response.ok) {
        if (vendor == 'BRAINTREE') {
          const nonce_res = await sk.post(`/api/payment-methods/${paymentMethod.value}/3ds-token/`);
          const nonce_res_body = await nonce_res.json();
          const secure_nonce = nonce_res_body.nonce;
          const secure_bin = nonce_res_body.bin;
          const data = await response.json();
          let threeDSecureParameters = JSON.parse(document.getElementById("three-d-secure-parameters").textContent)
          threeDSecureParameters = keysToCamel(threeDSecureParameters);
          threeDSecureParameters["onLookupComplete"] = function (data, next) {
            // use `data` here, then call `next()`
            next();
          }
          threeDSecureParameters.nonce = secure_nonce;

          threeDSecure.verifyCard(threeDSecureParameters).then((response) => {
            // Send response.nonce to your server for use in your integration
            // The 3D Secure Authentication ID can be found
            //  at response.threeDSecureInfo.threeDSecureAuthenticationId
            const { liabilityShifted, liabilityShiftPossible } = response.threeDSecureInfo;
            let status = response.threeDSecureInfo.status

            if (liabilityShifted || !liabilityShiftPossible) {
              sk.post('/api/orders/cart/submit/', {
                payment_method_nonce: response.nonce,
                vendor: vendor
              }).then(function (response) {
                if (response.ok) {
                  tracking.trackPurchase();
                  window.location = '/checkout/success';
                } else {
                  button.disabled = false;

                  if (response.status == 406) {
                    response.json().then(function (error) {
                      if (error.detail.indexOf('phone') >= 0) {
                        // Display this both when user has not entered a phone and when
                        // the phone entered is not verified.
                        $('#new-phone-number-modal').modal('show');
                      } else if (error.detail == 'Field "payment_method" has not been supplied') {
                        utils.displayFlashMessage('Παρακαλώ επιλέξτε μία μέθοδο πληρωμής.', 'danger');
                      } else {
                        utils.displayFlashMessage(error.detail, 'danger');
                      }
                    });
                  } else {
                    response.text().then(function (text) {
                      alert(`Error: ${text}`);
                    });
                  }
                }
              });
            } else {
              logError(status)
              utils.displayFlashMessage('Κάτι πήγε στραβά με την πληρωμή, παρακαλώ προσπαθήστε εκ νέου.', 'danger');
            }
          })
            .catch(function (error) {
              logError(error)
              utils.displayFlashMessage('Κάτι πήγε στραβά με την πληρωμή, παρακαλώ προσπαθήστε εκ νέου.', 'danger');
            });
        } else if (vendor == 'NBG') {
          const sessionId = document.getElementById('nbg-drop-in-ui-container').getAttribute('data-session-id');
          const merchantId = document.getElementById('nbg-drop-in-ui-container').getAttribute('data-merchant-id');

          let div = document.createElement('div');
          div.setAttribute('id', '3DSinit');
          document.body.appendChild(div);

          ThreeDS.configure({
            merchantId: merchantId,
            sessionId: sessionId,
            containerId: '3DSinit',
            callback: function () {
              if (ThreeDS.isConfigured()) {
                console.log('Done with configure');
              }
            },
            configuration: {
              userLanguage: 'el-GR', //Optional parameter
              wsVersion: 65,
            }
          });

          const optionalParams = {
            sourceOfFunds: {
              type: 'CARD'
            },
          };

          let { transaction_id: transactionId, order_id: orderId } = await updateSession(sessionId, merchantId, token)

          ThreeDS.initiateAuthentication(orderId, transactionId, async function (data) {
            if (data && data.error) {
              const error = data.error;
              logError(error)

              button.disabled = false;
              utils.displayFlashMessage('Κάτι πήγε στραβά με την πληρωμή, παρακαλώ προσπαθήστε εκ νέου.', 'danger');
            } else {
              switch (data.gatewayRecommendation) {
                case 'PROCEED':

                  let initAuthDiv = document.createElement('div');
                  initAuthDiv.innerHTML = data.htmlRedirectCode
                  initAuthDiv.setAttribute('hidden', 'true')
                  document.body.appendChild(initAuthDiv);
                  eval(document.getElementById('initiate-authentication-script').text)

                  if (transactionId) {
                    let attempts = 1
                    let offset = 500

                    authPayer(attempts, offset)

                    function authPayer(attempts, offset) {
                      const optionalParams = {
                        fullScreenRedirect: true,
                      };

                      ThreeDS.authenticatePayer(orderId, transactionId, async function (data) {
                        if (!data.error) {
                          // let result = await submitOrder(token, vendor, sessionId)
                          // console.log(result)
                        }

                        if (attempts >= 5) {
                          button.disabled = false;
                          utils.displayFlashMessage('Κάτι πήγε στραβά με την πληρωμή, παρακαλώ προσπαθήστε εκ νέου.', 'danger');
                          return
                        }

                        offset = 1000 * (attempts + 1)

                        setTimeout(function () {
                          authPayer(attempts + 1);
                        }, offset);
                      }, optionalParams);
                    }
                  }

                  break;
                default:
                  button.disabled = false;
                  utils.displayFlashMessage('Παρακαλώ επιλέξτε μία νέα μέθοδο πληρωμής', 'danger')
                  break;
              }
            }
          }, optionalParams);
        }
      } else {
        button.disabled = false;
        response.json().then(body => utils.displayFlashMessage(body.detail, 'danger'));
      }
    });
  });

  // Enforce ToS
  $('input[data-tos="toggle"]').on('change', e => {
    const checked = $(e.target).is(':checked');
    $('button[data-tos="require"]').attr('disabled', !checked);
  });
});

setTimeout(() => {
  $('.messages-container .alert').alert('close');
}, 10000);

$(function () {
  $('[data-toggle="tooltip"]').tooltip();

  $('input.postal-code').on('keyup', (e) => {
    const disabled = e.target.value.length != 5;
    const tooltip = (disabled) ? 'enable' : 'disable';
    const $button = $(`button[data-validate-from="#${e.target.id}"]`);

    console.debug('tooltip on', $button, tooltip);
    $button.attr('disabled', disabled).parent().tooltip(tooltip);
  });

  // Cookie policy
  const $cookieNotice = $('.cookie-notice');
  const cookiePolicyKey = 'cookiePolicyAccepted';
  const cookiePolicyAccepted = sessionStorage.getItem(cookiePolicyKey);

  if (!cookiePolicyAccepted) {
    $cookieNotice.removeAttr('hidden');
  }

  $cookieNotice.find('button.accept-cookie-policy').on('click', e => {
    sessionStorage.setItem(cookiePolicyKey, '1');
    $cookieNotice.attr('hidden', '');
  });
});


// Track Google Analytics 4 e-commerce events based on page views.
// https://support.google.com/analytics/answer/9267735
document.addEventListener('DOMContentLoaded', () => {
  if (location.pathname.startsWith('/products/')) {
    tracking.trackViewItemList();
  }

  if (location.pathname == '/checkout/location/') {
    tracking.trackBeginCheckout();
  }

  if (location.pathname == '/checkout/confirm/') {
    tracking.trackAddShippingInfo();
  }
});

function logError(error) {
  if (window.Raven) {
    Raven.captureMessage("Payment Method Request Error", {
      extra: {
        err: error,
      },
    });
  } else {
    console.log(`3DS: ${error}`)
  }
}

async function updateSession(sessionId, merchantId, token) {
  let data = {
    merchantId: merchantId,
    sessionId: sessionId,
    data: {
      sourceOfFunds: {
        token: token
      },
      authentication: {
        redirectResponseUrl: window.location.origin + `/checkout/3ds/${sessionId}/`,
        channel: 'PAYER_BROWSER'
      }
    }
  }

  let response = await sk.post(`/api/payment-methods/${token}/update-session/`, data);
  let result = await response.json();

  return result
}
function toCamel(str) {
  return str.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

function isObject(obj) {
  return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

function keysToCamel(obj) {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj)
      .forEach((k) => {
        n[toCamel(k)] = keysToCamel(obj[k]);
      });

    return n;
  }

  if (Array.isArray(obj)) {
    return obj.map((o) => keysToCamel(o));
  }

  return obj;
};

window.keysToCamel = keysToCamel
