//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { sk } from '../sk';

class SparkleanPaymentMethodDeleteButton extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    const row = document.querySelector(`.payment-method-row[data-token="${this.dataset.token}"]`);
    const paymentMethodUrl = `/api/payment-methods/${this.dataset.token}/`;

    this.addEventListener('click', () => {
      if (row.classList.contains('disabled')) {
        return;
      }

      const deletePaymentMethodButton = document.getElementById("delete-payment-method-button");

      deletePaymentMethodButton.addEventListener('click', ()=> {
        row.classList.add('disabled');

        sk.delete(paymentMethodUrl)
        .then(response => {
          row.classList.remove('disabled');
          if (response.ok) {
            row.parentElement.removeChild(row);  // This will give a snappier feeling
            document.querySelector('sk-payment-method-list').refresh();
            $("#delete-payment-method-modal").modal("hide");
          } else {
            response.json().then(data => {
              alert(data.detail);
            });
          }
        })
        .catch(() => {
          row.classList.remove('disabled');
          $("#delete-payment-method-modal").modal("hide");
          alert('Ουπς! Δεν μπορέσαμε να διαγράψουμε τη μέθοδο πληρωμής σου.');
        });
      });
    });
  }
}

// Define the new element
customElements.define('sk-payment-method-delete-button', SparkleanPaymentMethodDeleteButton);
