//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';


$(() => {
  const form = <HTMLFormElement>document.querySelector('#sign-up-form');

  $('#sign-up-modal').on('shown.bs.modal', function () {
    $('#sign-up-email-address').focus();
  });

  $('#sign-up-modal-sign-in-button').on('click', function () {
    $('#sign-up-modal').modal('hide');
    $('#sign-in-modal').modal('show');
  });

  $(form).on('submit', function (e) {
    e.preventDefault();
    let data = new FormData(form);

    sk.post(form.action, data).then(function (response) {
      if (response.ok) {
        sessionStorage.setItem('openPhoneNumberModal', '1');
        window.location.reload();
      } else {
        alert('Δεν μπορέσαμε να δημιουργήσουμε το λογαριασμό σας!');
      }
    });
  });
});
