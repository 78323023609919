//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2020 SourceLair Private Company. All rights reserved.
//

// This modules contains tracking utilities for Google Analytics 4's e-commerce funnel.
// Docs: https://support.google.com/analytics/answer/9267735

import { cart } from './state';

declare const gtag: Function;

// Google Analytics 4 should be considered ready, only if the `gtag` function is available.
const gtagIsReady = typeof gtag != 'undefined';

// Track Google Analytics 4 events, only if Google tag is ready.
const track = (kind, name, data) => (gtagIsReady) ? gtag(kind, name, data) : console.log(`Tracking(false)`, kind, name, data);

// Returns the current category ID and name, based URL path and DOM manipulation accordingly.
const getCurrentCategory = () => {
  const productCategoryPathRegex = /^\/products\/([^/]+)\/$/;
  const productCategoryMatch = location.pathname.match(productCategoryPathRegex);

  if (productCategoryMatch) {
    const categoryId = productCategoryMatch[1];
    const categoryName = document.querySelector('.categories-navigation .active').textContent.trim();
    return { id: categoryId, name: categoryName };
  }
}

// Transform a cart entry to an e-commerce item that can be tracked for Google Analytics 4 e-commerce funnel.
const entryToItem = entry => {
  const category = getCurrentCategory();
  const item = {
    'item_id': entry.product,
    'item_name': entry.productTitle,
    'item_list_id': (category) ? category.id : null,
    'item_list_name': (category) ? category.name : null,
    'item_category': (category) ? category.name : null,
    'value': entry.productPrice,
  }
  return item;
}

// Capture common order info for Google Analytics 4, from the current state.
const captureOrderInfo = () => {
  return new Promise<object>(resolve => {
    // We want to run this with a 100ms delay, to make sure that the state has been initialised.
    setTimeout(() => {
      const coupon = (cart.coupon) ? cart.coupon.code : null;
      const items = cart.entries.map(entryToItem);
      const value = cart.getSubTotal() - cart.discount;
      const orderInfo = {
        'currency': 'EUR',
        'coupon': coupon,
        'items': items,
        'value': value,
      };
      resolve(orderInfo);
    }, 100);
  });
}

// https://developers.google.com/gtagjs/reference/aw-events#view_item_list
export const trackViewItemList = () => {
  const category = getCurrentCategory();

  track('event', 'view_item_list', {
    'item_list_name': category.name,
    'item_list_id': category.id,
  });
}

// https://developers.google.com/gtagjs/reference/aw-events#add_to_cart
export const trackAddToCart = (entry) => {
  const item = entryToItem(entry);

  track('event', 'add_to_cart', {
    'currency': 'EUR',
    'value': entry.finalPrice,
    'items': [item],
  });
}

// https://developers.google.com/gtagjs/reference/aw-events#remove_from_cart
export const trackRemoveFromCart = (entry) => {
  const item = entryToItem(entry);

  track('event', 'remove_from_cart', {
    'currency': 'EUR',
    'value': entry.finalPrice,
    'items': [item],
  });
}

// https://developers.google.com/gtagjs/reference/aw-events#begin_checkout
export const trackBeginCheckout = async () => {
  const orderInfo = await captureOrderInfo();

  track('event', 'begin_checkout', orderInfo);
}

// https://developers.google.com/gtagjs/reference/aw-events#add_shipping_info
export const trackAddShippingInfo = async () => {
  const orderInfo = await captureOrderInfo();

  track('event', 'add_shipping_info', orderInfo);
}

// https://developers.google.com/gtagjs/reference/aw-events#add_payment_info
export const trackAddPaymentInfo = async (paymentType) => {
  const orderInfo = await captureOrderInfo();

  track('event', 'add_payment_info', {
    ...orderInfo,
    'payment_type': paymentType,
  });
}

// https://developers.google.com/gtagjs/reference/aw-events#purchase
export const trackPurchase = async () => {
  const orderInfo = await captureOrderInfo();

  track('event', 'purchase', orderInfo);
}
