//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { observe } from 'mobx';

import * as moment from 'moment';
import 'moment/locale/el';

import React from 'react';
import ReactDOM from 'react-dom';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { sk } from '../sk';
import { availableTimeSlots, cart } from '../state';


class SparkleanSlotSelector extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    let selector = this;
    let row = document.createElement('div');
    let dateColumn = document.createElement('div');
    let timeColumn = document.createElement('div');

    // Construct the date column
    let dateHeading = document.createElement('h3');
    let datePickerContainer = document.createElement('div');
    datePickerContainer.classList.add('form-control', 'date-selector');

    dateHeading.textContent = 'Επιλογή ημέρας';
    dateColumn.appendChild(dateHeading);
    dateColumn.appendChild(datePickerContainer);
    dateColumn.classList.add('col', 'slot-date-selector');
    row.appendChild(dateColumn);

    // Construct the time column
    let timeHeading = document.createElement('h3');
    let timeSelect = document.createElement('select');
    let timePlaceholder = document.createElement('option');
    timeHeading.textContent = 'Επιλογή ώρας';
    timeColumn.appendChild(timeHeading);
    timePlaceholder.disabled = true;
    timePlaceholder.selected = (selector.dataset.selected == '') ? true : false;
    timePlaceholder.textContent = 'Διαθέσιμες ώρες';
    timePlaceholder.classList.add('time-slot-date-placeholder');
    timeSelect.appendChild(timePlaceholder);
    timeColumn.classList.add('col', 'slot-date-selector');
    timeSelect.classList.add('time-selector', 'form-control');
    timeSelect.required = true;
    timeColumn.appendChild(timeSelect);
    row.appendChild(timeColumn);
    row.classList.add('row');
    selector.appendChild(row);

    let stateKey = selector.dataset.key + 's';
    let urlSuffix = stateKey.replace('_', '-');

    timeSelect.addEventListener('change', () => {
      const selectedSlot = timeSelect.value || null;
      let payload = {};

      payload[selector.dataset.key] = selectedSlot;
      cart.partialUpdate(payload);
    });

    selector.renderDatePickerInput();

    observe(cart, selector.dataset.key, (update) => {
      selector.dataset.selected = update.newValue.uuid;
    });

    observe(availableTimeSlots, stateKey, (update) => {
      selector.renderDatePickerInput();
      selector.renderSelectedTimeSlot();
    });

    availableTimeSlots.fetchByKey(stateKey);
  }

  renderDatePickerInput() {
    const selector = this;
    const stateKey = `${selector.dataset.key}s`;
    const availableDates = availableTimeSlots.availableDatesByKey(stateKey);
    const slots = availableTimeSlots[stateKey];
    const selectedArray = slots.filter(slot => slot.uuid == selector.dataset.selected);
    const selected = (selectedArray.length) ? selectedArray[0] : null;
    const datePickerContainer = this.querySelector('.date-selector');
    const momentAvailableDates = availableDates.map(date => moment(date, 'YYYY-MM-DD'));
    const value = (selected) ? moment(selected.start).toDate() : '';
    const firstAvailableDate = momentAvailableDates[0];
    const initialMonth = (firstAvailableDate) ? firstAvailableDate.toDate() : new Date();

    ReactDOM.unmountComponentAtNode(datePickerContainer);

    let component = React.createElement(
      DayPickerInput,
      {
        onDayChange: function(date, modifiers) {
          const slotDate = moment(date).format('YYYY-MM-DD');
          selector.renderTimeSlotsForDate(slotDate);
          const firstSlot = availableTimeSlots.getSlotsInKeyForDate(stateKey, slotDate)[0];
          let payload = {};
          payload[selector.dataset.key] = firstSlot.uuid;
          cart.partialUpdate(payload);
        },
        dayPickerProps: {
          disabledDays: date => !availableDates.includes(moment(date).format('YYYY-MM-DD')),
        },
        formatDate: (date, format) => moment(date).format('dddd D MMMM'),
        keepFocus: false,
        placeholder: 'Διαθέσιμες ημέρες',
        value: value,
        initialMonth: initialMonth
      }
    );
    ReactDOM.render(component, datePickerContainer);
  }

  renderTimeSlotsForDate(date) {
    let selector = this;
    let timeSelect = selector.querySelector('.time-selector');
    let stateKey = selector.dataset.key + 's';
    let selected = selector.dataset.selected;

    // Remove any existing time slot options
    timeSelect.querySelectorAll('option.time-slot-option').forEach((option) => {
      timeSelect.removeChild(option);
    });

    if (!date) {
      if (selected) {
        date = availableTimeSlots.getDateBySlotId(selected);
      } else {
        return;
      }
    }

    // Store in relevantTimeSlots, all slots that belong to the selected date
    const relevantTimeSlots = availableTimeSlots.getSlotsInKeyForDate(stateKey, date);

    // Render the relevant time slots as options.
    relevantTimeSlots.forEach(slot => {
      const slotStartTime = moment(slot.start).format('HH:mm');
      const slotEndTime = moment(slot.end).format('HH:mm');
      const option = document.createElement('option');
      option.classList.add('time-slot-option');
      option.value = slot.uuid;
      option.textContent = `${slotStartTime} - ${slotEndTime}`;
      option.selected = slot.uuid == selected;
      timeSelect.appendChild(option);
    });
  }

  renderSelectedTimeSlot() {
    let selector = this;
    let timeSelect = selector.querySelector('.time-selector');
    let stateKey = selector.dataset.key + 's';

    if (!selector.dataset.selected) {
      selector.renderDatePickerInput();
      selector.renderTimeSlotsForDate();
    } else {
      let potentialSelectedTimeSlots = availableTimeSlots[stateKey].filter((slot) => {
        return slot.uuid == selector.dataset.selected;
      });

      let selectedTimeSlot = (potentialSelectedTimeSlots.length) ? potentialSelectedTimeSlots[0] : null;

      // This is the case when the previously selected delivery slot
      // is not valid any more.
      if (!selectedTimeSlot) {
        selector.renderDatePickerInput();
        selector.renderTimeSlotsForDate();
        return;
      }

      let selectedTimeSlotDate = moment(selectedTimeSlot.start).format('YYYY-MM-DD');
      selector.renderDatePickerInput();
      selector.renderTimeSlotsForDate();
    }
  }
}

// Always refresh the available delivery slots
// when the pickup slot gets updated
observe(cart, 'pickup_slot', (update) => availableTimeSlots.fetchByKey('delivery_slots'));

// Define the new element
customElements.define('sk-slot-selector', SparkleanSlotSelector);
