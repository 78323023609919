//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { cart } from '../state';
import { serializeCartEntryElement, parsePrice } from '../utils';


export class SparkleanCartEntryQuantityAdd extends HTMLElement {
  constructor() {
    super();
  }

  get entry() {
    return document.querySelector(`sk-cart-entry[data-product="${this.dataset.product}"]`);
  }

  connectedCallback() {
    let self = this;  // We need to use it inside callbacks
    let addQuantity = parseInt(self.dataset.addQuantity, 10) || 1;
    let minQuantity = parseInt(self.dataset.minQuantity, 10);

    // Set up attributes
    self.role = 'button';
    self.classList.add('input-group-btn');
    self.innerHTML = (addQuantity >= 0) ? '+' : '-';

    self.addEventListener('click', (e) => {
      e.stopPropagation();

      let inCart = (self.entry) ? true : false;
      let currentQuantity = (!inCart) ? 0 : parseInt(self.dataset.cachedQuantity, 10) || parseInt(self.entry.dataset.quantity, 10);
      let updatedQuantity = currentQuantity + addQuantity;

      // If the proposed updated quantity is less than zero, ignore it
      if (updatedQuantity < 0) {
        return;
      }

      if (updatedQuantity < minQuantity) {
        return;
      }

      self.dataset.cachedQuantity = updatedQuantity;

      // Visual hack for instant feedback
      document.querySelectorAll(`.entry-quantity[data-product="${self.dataset.product}"]`).forEach((el) => {
        el.value = self.dataset.cachedQuantity;
      })

      // If an update is scheduled already, just cancel it
      if (self.dataset.lastTimer) {
        clearTimeout(parseInt(self.dataset.lastTimer, 10));
      }

      // Schedule update in 400ms min
      self.dataset.lastTimer = setTimeout(() => {
        if (self.entry) {
          if (updatedQuantity == 0) {
            cart.delete(self.entry.dataset.product);
          } else {
            let updatedEntry = serializeCartEntryElement(self.entry);
            updatedEntry.quantity = updatedQuantity;
            updatedEntry.finalPrice = updatedEntry.productPrice * updatedQuantity;
            cart.update(self.entry.dataset.pk, updatedEntry)
          }
        } else {
          // This can only come from inside the catalog, so let's try to find
          // and source all the data.
          let catalogEntry = document.body.querySelector(`sk-catalog-entry[data-product="${self.dataset.product}"]`);
          let productPrice = parsePrice(catalogEntry.dataset.productPrice);
          let finalPrice = productPrice * updatedQuantity;

          cart.add({
            product: self.dataset.product,
            productTitle: catalogEntry.dataset.productTitle,
            productPrice: productPrice,
            productImage: catalogEntry.dataset.productImage,
            quantity: updatedQuantity,
            finalPrice: finalPrice
          });
        }
      }, 400);
    });
  }
}

// Define the new element
customElements.define('sk-cart-entry-quantity-add', SparkleanCartEntryQuantityAdd);
