//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { sk } from '../sk';

export class SparkleanAddressList extends HTMLElement {
  constructor() {
    super();
  }

  refresh(callback) {
    let addressListContainer = this;

    sk.get('/components/addresses/').then(function (response) {
      return response.text().then(function (text) {
        $(addressListContainer).html(text);

        if (typeof callback == 'function') {
          callback();
        }
      });
    });
  }
}

// Define the new element
customElements.define('sk-address-list', SparkleanAddressList);
