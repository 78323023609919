//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';
import sk from '../sk'


$(() => {
  const form = <HTMLFormElement>document.querySelector('#sign-in-modal .sign-in-form');

  $('#sign-in-modal').on('shown.bs.modal', () => $('.sign-in-email-address').focus());

  $('#sign-in-modal').on('hidden.bs.modal', () => form.reset());

  $('#sign-up-button').on('click', () => {
    $('#sign-in-modal').modal('hide');
    $('#sign-up-modal').modal('show');
  });

  $('.forgot-password-button').on('click', () => {
    $('#sign-in-modal').modal('hide');
    $('#forgot-password-modal').modal('show');
  });

  $('.sign-in-form').on('submit', e => {
    e.preventDefault();
    const currentForm = <HTMLFormElement>e.target;
    const errorsContainer = currentForm.querySelector('.errors-container');
    const data = new FormData(currentForm);

    errorsContainer.innerHTML = '';

    for (const btn of currentForm.querySelectorAll('.btn')) {
      (<HTMLButtonElement>btn).disabled = true;
    };

    sk.post(currentForm.action, data).then(response => {
      const next = (<HTMLInputElement>currentForm.querySelector('.sign-in-next')).value;

      if (response.ok) {
        if (next) {
          window.location.href = next;
        } else {
         window.location.reload();
        }
      } else {
        if (response.status >= 400 && response.status <= 499) {
          const error = document.createElement('sk-alert');

          error.classList.add('alert', 'alert-danger');

          response.text().then(text => {
            if (text.match(new RegExp(/invalid\scredentials/, 'i'))) {
              error.textContent = 'Λάθος email ή κωδικός';
            } else {
              error.textContent = text;
            }
            errorsContainer.appendChild(error);
          });
        } else {
          alert(`We could not log you in! Status: ${response.status}`);
        }
      }
    })
    .catch(() => alert('Unexpected error. Please report.'))
    .then(() => {  // Always do that.
      for (const btn of currentForm.querySelectorAll('.btn')) {
        (<HTMLButtonElement>btn).disabled = false;
      }
    });
  });
});
