//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';
import { SparkleanAddressList } from '../elements/sk-address-list';
import { SparkleanAddressSelector } from '../elements/sk-address-selector';

$(() => {
  const form = <HTMLFormElement>document.querySelector('#new-address-form');

  $(form).on('submit', function (e) {
    e.preventDefault();

    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    const button = form.querySelector('button');

    let data = {
      'street_name': $('#new-address-street-name').val(),
      'street_number': $('#new-address-street-number').val(),
      'postal_code': $('#new-address-postal-code').val(),
      'city': $('#new-address-city').val(),
      'area': $('#new-address-area').val(),
      'floor': $('#new-address-floor').val(),
      'name_on_ringer': $('#new-address-name-on-ringer').val(),
      'notes': $('#new-address-notes').val(),
      'coordinates': JSON.parse(<string>$('#new-address-coordinates').val()),
    };

    button.disabled = true;

    sk.post(form.action, data).then(function (response) {
      button.disabled = false;

      if (response.ok) {
        response.json().then((data) => {
          localStorage.setItem('lastAddress', data.uuid);
        });

        $('#new-address-modal').modal('hide');

        for (const element of document.querySelectorAll('sk-address-list')) {
          (<SparkleanAddressList>element).refresh();
        }

        for (const element of document.querySelectorAll('sk-address-selector')) {
          (<SparkleanAddressSelector>element).refresh();
        }

        form.reset();

        if (window.location.pathname == '/addresses/' && !document.querySelector('sk-address-list')) {
          window.location.reload();
        }
      } else {
        response.json().then(function(data) {
          alert(JSON.stringify(data));
        });
      }
    });
  });

  $('#new-address-modal').on('show.bs.modal', function (ev) {
    var addressEntry = ev.relatedTarget;

    $('#new-address-street-name').val(addressEntry.dataset.street_name);
    $('#new-address-street-number').val(addressEntry.dataset.street_number);
    $('#new-address-area').val(addressEntry.dataset.area || addressEntry.dataset.city);
    $('#new-address-city').val(addressEntry.dataset.city || addressEntry.dataset.area);
    $('#new-address-postal-code').val(addressEntry.dataset.postal_code);
    $('#new-address-coordinates').val(addressEntry.dataset.coordinates);
  });


});
