//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { observe } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';

import { cart } from '../state';
import * as utils from '../utils';

import { SparkleanCartEntryDelete } from './sk-cart-entry-delete';
import { SparkleanCartEntryQuantityAdd } from './sk-cart-entry-quantity-add';
import { SparkleanQuantityInput } from './sk-quantity-input';


class SparkleanCartEntryComponent extends React.Component {
  render() {
    return (
      React.createElement(
        'div',
        {
          className: 'list-group-item cart-item'
        },
        React.createElement(
          'div',
          {
            className: 'row align-items-center'
          },
          [
            React.createElement(
              'div',
              {
                className: 'col-1',
                key: `row-delete-${this.props.product}`
              },
              React.createElement(
                'sk-cart-entry-delete',
                {
                  'data-pk': this.props.pk,
                  'data-product': this.props.product,
                },
                ''
              )
            ),
            React.createElement(
              'div',
              {
                className: 'col-2 cart-item-image',
                key: `row-image-${this.props.product}`
              },
              React.createElement(
                'img',
                {
                  src: this.props.productImage,
                }
              )
            ),
            React.createElement(
              'div',
              {
                className: 'col-4 col-md',
                key: `row-product-info-${this.props.product}`
              },
              [
                React.createElement(
                  'h4',
                  {
                    className: 'cart-item-product',
                    key: `product-title-${this.props.pk}`
                  },
                  this.props.productTitle
                ),
                React.createElement(
                  'div',
                  {
                    className: 'cart-item-final-price',
                    key: `final-price-${this.props.pk}`
                  },
                  utils.formatPrice(this.props.productPrice)
                )
              ]
            ),
            React.createElement(
              'div',
              {
                className: 'col',
                key: `row-amount-${this.props.product}`
              },
              React.createElement(
                'div',
                {
                  className: 'input-group cart-item-quantity'
                },
                [
                  React.createElement(
                    'span',
                    {
                      className: 'input-group-btn',
                      key: `add-quantity-minus-1-${this.props.product}`
                    },
                    React.createElement(
                      'sk-cart-entry-quantity-add',
                      {
                        'data-product': this.props.product,
                        'data-add-quantity': '-1',
                        'data-min-quantity': this.props.minQuantity
                      },
                      ''
                    )
                  ),
                  React.createElement(
                    SparkleanQuantityInput,
                    {
                      product: this.props.product,
                      productTitle: this.props.productTitle,
                      productPrice: this.props.productPrice,
                      quantity: this.props.quantity,
                      key: `quantity-input-${this.props.product}`
                    }
                  ),
                  React.createElement(
                    'span',
                    {
                      className: 'input-group-btn',
                      key: `add-quantity-plus-1-${this.props.product}`
                    },
                    React.createElement(
                      'sk-cart-entry-quantity-add',
                      {
                        'data-product': this.props.product,
                        'data-add-quantity': '+1'
                      },
                      ''
                    )
                  )
                ]
              )
            )
          ]
        )
      )
    )
  }
}

export class SparkleanCartEntry extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    let self = this;

    let component = React.createElement(
      SparkleanCartEntryComponent,
      {
        pk: self.dataset.pk,
        minQuantity: self.dataset.minQuantity || 0,
        productImage: self.dataset.productImage,
        product: self.dataset.product,
        quantity: self.dataset.quantity,
        productTitle: self.dataset.productTitle,
        productPrice: self.dataset.productPrice,
        finalPrice: utils.formatPrice(self.dataset.finalPrice)
      },
      null
    );

    ReactDOM.render(component, self);
  }
}

// Define the new element
customElements.define('sk-cart-entry', SparkleanCartEntry);
