//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { sk } from '../sk';

class SparkleanPaymentMethodList extends HTMLElement {
  constructor() {
    super();
  }

  refresh(callback) {
    let paymentListContainer = this;

    sk.get(paymentListContainer.dataset.url).then((response) => {
      return response.text().then(function (text) {
        paymentListContainer.querySelector('.container').innerHTML = text;

        if (typeof callback == 'function') {
          callback();
        }
      });
    });
  }
}

// Define the new element
customElements.define('sk-payment-method-list', SparkleanPaymentMethodList);
