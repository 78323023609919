//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { observe } from 'mobx';

import { TimeSlotsSet } from './models';
import { Cart } from './cart/models';
import { parsePrice, serializeCartEntryElement } from './utils';


const cart = new Cart();
let availableTimeSlots = new TimeSlotsSet();

// If there is a cart state in the localStorage, load it.
let localStorageState = JSON.parse(localStorage.getItem('cart'));

if (localStorageState) {
  cart.entries = localStorageState.entries;
  cart.checksum = localStorageState.checksum;
  cart.coupon = localStorageState.coupon;
  cart.discount = localStorageState.discount || 0;
}

// When the DOM loads successfully, set the checksum attribute of the
// cart's state to the value of the corresponding data attribute of the
// body element.
document.addEventListener('DOMContentLoaded', () => {
  cart.checksum = document.body.dataset.cartChecksum || null;

  // When the checksum of the cart gets updated, update the corresponding
  // data attribute of the body element.
  observe(cart, 'checksum', (change) => {
    document.body.dataset.cartChecksum = change.newValue
  });

  if (!cart.isClientOnly()) {
    if (localStorageState) {
      cart.updateEntries(localStorageState.entries);
      localStorage.removeItem('cart');
    } else {
      // If no localStorage state exists, then set state based on the DOM
      // elements rendered in the mini cart.
      let miniCart = <HTMLElement>document.querySelector('.mini-cart');
      let entries = [];

      for (const element of miniCart.querySelectorAll('sk-cart-entry')) {
        entries.push(serializeCartEntryElement(element));
      }

      cart.entries = entries;
      cart.discount = parsePrice(miniCart.dataset.discount);
    }
  }
});

export { availableTimeSlots, cart };
