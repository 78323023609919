//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';


$(() => {
  const form = <HTMLFormElement>document.querySelector('#postal-code-subscription-form');


  $(form).on('submit', function(e) {
    e.preventDefault();

    let data = new FormData(form);

    sk.post(form.action, data).then((response) => {
      if (response.ok) {
        alert('Ευχαριστούμε! Μόλις είμαστε διαθέσιμοι στην περιοχή σου, θα σε ενημερώσουμε.');
        $('#postal-code-subscription-modal').modal('hide');
        window.location.pathname = '/products/';
      } else {
        alert('Oops! Κάτι πήγε στραβά :(.')
      }
    });
  });

});
