//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { extendObservable } from 'mobx';

import sk from '../sk';
import { parsePrice } from '../utils';
import * as tracking from '../tracking';

interface CartCoupon {
  code: string;
}


export class Cart {
  public readonly url = '/api/orders/cart/';
  public readonly couponUrl = '/api/orders/cart/coupon/';
  public checksum: string;
  public discount: number;
  public entries: any[];
  public pickup_slot: any;
  public delivery_slot: any;
  public coupon: CartCoupon;
  public payment_method: string;

  constructor() {
    extendObservable(this, {
      entries: [],
      checksum: null,
      coupon: null,
      discount: 0,
      pickup_slot: null,
      delivery_slot: null,
      payment_method: null,
    });
  }

  storeCart(data) {
    this.checksum = data.checksum;
    this.discount = parsePrice(data.discount);
    this.entries = data.entries.map((entry) => {
      let quantity = parseInt(entry.quantity, 10)
      let productPrice = parsePrice(entry.product.price);
      let finalPrice = quantity * productPrice;

      return {
        pk: entry.uuid,
        product: entry.product.uuid,
        quantity: quantity,
        productTitle: entry.product.title,
        productImage: entry.product.image,
        productPrice: productPrice,
        finalPrice: finalPrice
      }
    });
    this.pickup_slot = data.pickup_slot;
    this.delivery_slot = data.delivery_slot;
    this.coupon = data.coupon;
    this.payment_method = data.payment_method;
  }

  async partialUpdate(payload) {
    try {
      const response = await sk.patch(this.url, payload)

      if (response.ok) {
        try {
          const data = await response.json();
          this.storeCart(data);
        } catch (error) {
          alert('Could not decode cart update response!');
        }
      } else {
        alert('Could not update cart!')
      }
    } catch (error) {
      alert('Could not request cart update!');
    }
  }

  fetchCart() {
    let self = this;

    sk.get(this.url)
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((data) => {
              self.storeCart(data);
            });
        } else {
          throw response;
        }
      })
      .catch((err) => {
        throw err;
        alert('Δεν μπορέσαμε να ανανεώσουμε το καλάθι και ενημερώσαμε το team!');
      });
  }

  // For now source entries from the top right cart DOM children
  getEntries() {
    if (this.isClientOnly()) {
      let clientEntries = this.entries.map((entry) => ({
        pk: entry.pk,
        product: entry.product,
        productTitle: entry.productTitle,
        productPrice: entry.productPrice,
        productImage: entry.productImage,
        quantity: entry.quantity,
        finalPrice: entry.finalPrice
      }));

      return clientEntries;
    }

    let _entries = [];

    for (const entry of document.body.querySelectorAll('#mini-cart-container sk-cart-entry')) {
      _entries.push({
        pk: (<HTMLElement>entry).dataset.pk,
        product: (<HTMLElement>entry).dataset.product,
        quantity: (<HTMLElement>entry).dataset.quantity
      });
    }

    return _entries;
  }

  getEntryByProduct(product) {
    let entriesOfProduct = this.entries.filter((entry) => {
      return entry.product == product;
    });

    return (entriesOfProduct.length) ? entriesOfProduct[0] : null;
  }

  getQuantityByProduct(product) {
    let entry = this.getEntryByProduct(product);
    return (entry) ? entry.quantity : 0;
  }

  getSubTotal() {
    return this.entries.map((entry) => entry.finalPrice).reduce((a, b) => (a + b), 0);
  }

  isClientOnly() {
    return !Boolean(document.body.dataset.user.length);
  }

  async add(entry) {
    const entries = this.getEntries();

    entries.push(entry);

    const updateData = await this.updateEntries(entries);

    tracking.trackAddToCart(entry);

    return updateData;
  }

  addCoupon(code) {
    let self = this;
    let req = sk.post(this.couponUrl, {
      code: code
    })
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((data) => {
              self.coupon = data;
              self.fetchCart();
            });
        } else {
          response.json().then(data => {
            if (data.detail) {
              alert(data.detail);
            } else {
              alert(`Error: ${JSON.stringify(data)}`);
            }
          });
        }
      })
      .catch((error) => {
        alert('Could not connect to server.')
      });

    return req;
  }

  deleteCoupon() {
    const self = this;
    const req = sk.delete(this.couponUrl);

    req.then((response) => {
      if (response.ok) {
        self.coupon = null;
        self.fetchCart();
      } else {
        response.json().then(data => {
          if (data.detail || data.coupon) {
            alert(data.detail || data.coupon);
          } else {
            alert(`Error: ${JSON.stringify(data)}`);
          }
        });
      }
    })
      .catch((error) => {
        alert('Could not connect to server.')
      });

    return req;
  }

  async delete(product) {
    const currentEntries = this.getEntries();
    const entryToBeRemoved = currentEntries.filter(entry => {
      return entry.product == product;
    })[0];
    const updatedEntries = currentEntries.filter((entry) => {
      return entry.product != product;
    });
    const updateData = await this.updateEntries(updatedEntries);

    tracking.trackRemoveFromCart(entryToBeRemoved);

    return updateData;
  }

  update(pk, updatedEntry) {
    // If the quantity of the updated entry is zero, then delete it.
    if (updatedEntry.quantity == 0) {
      return this.delete(updatedEntry.product);
    }

    let self = this;
    let entries = this.getEntries().map((entry) => {
      if (self.isClientOnly()) {
        return (entry.product == updatedEntry.product) ? updatedEntry : entry;
      }
      return (entry.pk == pk) ? updatedEntry : entry;
    });

    return this.updateEntries(entries);
  }

  async updateEntries(entries) {
    if (this.isClientOnly()) {
      this.entries = entries;
      localStorage.setItem('cart', JSON.stringify(this));
      return;
    }

    try {
      const response = await sk.post(`${this.url}update_entries/`, {
        checksum: this.checksum,
        entries: entries
      });

      try {
        const data = await response.json();

        if (!response.ok) {
          let message = 'Αδυναμία ενημέρωσης καλαθιού';
          if (data.entries) {
            if (data.entries[0]) {
              if (data.entries[0].non_field_errors) {
                message = data.entries[0].non_field_errors[0]
              }
            }
          }
          alert(message)
          return
        }

        this.storeCart(data);
        return data;
      } catch (e) {
        alert('Σφάλμα εξυπηρετητή.')
      }
    } catch (error) {
      alert('Could not request cart update!');
    }
  }

  async updatePaymentMethod(paymentMethodToken: string, vendor: string) {
    return await this.partialUpdate({ payment_method: paymentMethodToken, vendor: vendor });
  }
}
