//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { cart } from '../state';


class SparkleanCart extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    var self = this;

    // Handle the coupon form and the subtotal, after all DOM children load.
    setTimeout(() => {
      var couponForm = self.querySelector('.apply-coupon-form');

      if (couponForm) {
        couponForm.addEventListener('submit', function(e) {
          e.preventDefault();

          var element = couponForm.querySelector('input[name="code"]');
          var code = element.value;

          cart.addCoupon(code);
        });
      }
    }, 4);
  }
}


// Define the new element
customElements.define('sk-cart', SparkleanCart);
