//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { sk } from '../sk';
import { cart } from '../state';
import * as tracking from '../tracking';


const handlePaymentMethodInputCheck = async element => {
  const vendor = element.getAttribute('data-vendor');
  await cart.updatePaymentMethod(element.value, vendor);

  if (vendor == "NBG") {
    const nbgContainer = document.getElementById("nbg-drop-in-ui-container");
    const paymentMethodToken = document.querySelector("input[type='radio'][name=payment_method]:checked").value;
    const nbgSessionId = await createNBGSession(paymentMethodToken);

    nbgContainer.setAttribute("data-session-id", nbgSessionId);
  }
  
  const paymentMethodContainer = element.parentElement.parentElement;
  const paymentTypeImage = paymentMethodContainer.querySelector('img');
  const paymentTypeIsPayPal = paymentTypeImage.src.includes('paypal');
  const paymentType = (paymentTypeIsPayPal) ? 'PayPal' : 'Card';

  tracking.trackAddPaymentInfo(paymentType);
}
const handlePaymentMethodInputChange = element => {
  element.addEventListener('change', () => {
    if (element.checked) {
      handlePaymentMethodInputCheck(element);
    }
  });
}


class SparkleanCartPaymentMethodForm extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    setTimeout(() => {
      const paymentMethodOptions = Array.from(this.querySelectorAll('input'));
      paymentMethodOptions.forEach(handlePaymentMethodInputChange);
    }, 4);
  }
}

// Define the new element
customElements.define('sk-cart-payment-method-form', SparkleanCartPaymentMethodForm);

async function createNBGSession(payemntMethodToken) {
  let response = await sk.post(`/api/payment-methods/${payemntMethodToken}/create-session/`);
  let result = await response.json();

  return result.nbg_session_id;
}
