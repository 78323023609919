//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { sk } from '../sk';


class SparkleanAddressDeleteButton extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    var button = this;
    var addressUrl = '/api/addresses/' + button.dataset.addressId + '/';

    button.addEventListener('click', function (e) {
      sk.delete(addressUrl).then(function () {
        document.querySelector('sk-address-list').refresh();
      });
    });
  }
}

// Define the new element
customElements.define('sk-address-delete-button', SparkleanAddressDeleteButton);
