//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

/**
 * Return if the given array contains the given element
 * @param {Array} array The array to search for the given element.
 * @param {Object} el The element to look for into the array
 */
export function contains(arr, el) {
  return arr.indexOf(el) >= 0;
};

export function parsePrice(price) {
  const rawPrice = (typeof price == 'string') ? price.replace(',', '.') : price;
  return parseFloat(rawPrice);
}

export function formatPrice(price) {
  return parsePrice(price).toFixed(2);
}

export function serializeCartEntryElement(element) {
  let quantity = parseInt(element.dataset.quantity, 10);
  let productPrice = parsePrice(element.dataset.productPrice);
  let entry = {
    pk: element.dataset.pk || null,
    product: element.dataset.product,
    quantity: quantity,
    productTitle: element.dataset.productTitle,
    productImage: element.dataset.productImage,
    productPrice: productPrice,
    finalPrice: quantity * productPrice
  };

  return entry;
}

export function displayFlashMessage(message, severity) {
  let container = document.getElementById('main-messages-container');
  let messageContainer = document.createElement('div');
  let closeButton = document.createElement('button');

  messageContainer.classList.add(
    'alert', `alert-${severity || 'info'}`, 'alert-dismissible', 'fade', 'show'
  )
  messageContainer.textContent = message;

  closeButton.innerHTML = '<span aria-hidden="true">&times;</span>';
  closeButton.classList.add('close');
  closeButton.type = 'button';
  closeButton.dataset.dismiss = 'alert';
  closeButton.setAttribute('aria-label', 'Close');

  messageContainer.appendChild(closeButton);
  container.appendChild(messageContainer);

  return messageContainer;
}
