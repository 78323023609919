//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as React from 'react';

import { cart } from '../state';
import { formatPrice } from '../utils';


export class SparkleanCartCoupon extends React.Component<{code: string}> {
  render() {
    return [
      React.createElement(
        'div',
        {
          className: 'col-md-1 cart-coupon-delete-container',
          key: 'cart-coupon-delete-col'
        },
        React.createElement(
          'span',
          {
            ariaHidden: 'true',
            className: 'cart-coupon-delete',
            role: 'button',
            key: 'cart-coupon-delete-button',
            onClick: e => cart.deleteCoupon(),
          },
          '×',
        )
      ),
      React.createElement(
        'div',
        {
          className: 'col',
          key: 'cart-coupon-code-col'
        },
        React.createElement(
          'h3',
          {},
          `Κουπόνι (${this.props.code})`
        )
      ),
      React.createElement(
        'div',
        {
          className: 'col text-right',
          key: 'cart-coupon-discount-col'
        },
        React.createElement(
          'div',
          {
            className: 'coupon-discount'
          },
          formatPrice(cart.discount)
        )
      )
    ];
  }
}
