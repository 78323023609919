//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import 'whatwg-fetch';

/**
 * sk is a simple proxy to `fetch` that:
 *   - Sets the right credential policy
 *   - Adds the CSRF token to the request
 *   - Allows for setting a JSON body
 */
export function sk(url, options) {
  options.credentials = 'include';
  options.headers = options.headers || {};
  options.headers['x-csrftoken'] = sk._getCsrfToken();
  options.headers['accept-language'] = document.documentElement.lang;

  if (options.body && typeof options.body == 'object' && !(options.body instanceof FormData)) {
    options.body = JSON.stringify(options.body);
    options.headers['content-type'] = 'application/json';
  }

  return fetch(url, options);
};

sk._getCsrfToken = function () {
  return document.querySelector('#csrf-token-container input').value;
};

sk._method = function (method, url, data, options) {
  options = options || {};
  options.method = method;
  options.body = data;

  return sk(url, options);
};

sk.get = function (url, data, options) {
  return sk._method('GET', url, data, options);
};

sk.delete = function (url, data, options) {
  return sk._method('DELETE', url, data, options);
};

sk.patch = function (url, data, options) {
  return sk._method('PATCH', url, data, options);
};

sk.post = function (url, data, options) {
  return sk._method('POST', url, data, options);
};

sk.put = function (url, data, options) {
  return sk._method('PUT', url, data, options);
};

export default sk;
