//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { observe } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';

import { cart } from '../state';


export class SparkleanQuantityInput extends React.Component {
  constructor(props) {
    super(props);

    let self = this;

    self.state = {
      value: props.quantity
    };

    observe(cart, 'entries', () => {
      let newQuantity = cart.getQuantityByProduct(props.product);

      self.setState({
        value: parseInt(newQuantity, 10)
      });
    });
  }

  render() {
    return React.createElement('input', {
      type: 'number',
      name: 'quantity',
      min: 0,
      max: 20,
      className: 'form-control entry-quantity product-quantity cart-item-quantity-value',
      value: this.state.value,
      disabled: true
    }, null);
  }
}
