//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { extendObservable } from 'mobx';

import * as moment from 'moment';

import { sk } from './sk';


interface TimeSlot {
  uuid: string;
  start: string;
  end: string;
}

export class TimeSlotsSet {
  public pickup_slots: TimeSlot[];
  public delivery_slots: TimeSlot[];

  constructor() {
    extendObservable(this, {
      pickup_slots: [],
      delivery_slots: []
    });
  }

  getSlotDate(slot: TimeSlot): string {
    const date = (slot) ? moment(slot.start).format('YYYY-MM-DD') : null;
    return (date == 'Invalid date') ? null : date;
  }

  getDateBySlotId(uuid: string) {
    const slots = this.pickup_slots.concat(this.delivery_slots);
    const matchingSlots = slots.filter(slot => slot.uuid == uuid);
    const slot = (matchingSlots.length) ? matchingSlots[0] : null;
    return this.getSlotDate(slot);
  }

  getSlotsInKeyForDate(key: string, date: string): TimeSlot[] {
    return this[key].filter(slot => date == this.getSlotDate(slot));
  }

  availableDatesByKey(key: string): string[] {
    const slots = this[key];
    const dates = slots.map(slot => this.getSlotDate(slot));
    const uniqueDates = dates.filter((date, index) => dates.indexOf(date) == index);
    return uniqueDates
  }

  fetchByKey(key) {
    let self = this;
    let urlSuffix = key.replace('_', '-');

    return sk.get('/api/orders/cart/' + urlSuffix + '/')
      .then(response => {
        if (response.ok) {
          response.json().then(data => self[key] = data)
          .catch(error => {
            alert('Could not parse the fetched time slots!');
            throw error;
          });
        } else {
          if (response.status == 400 && urlSuffix == 'delivery-slots') {
            // Don't remember what I was thinking: self.key = [];
          } else {
            alert('We could not fetch the available time slots!');
            throw response;
          }
        }
      })
      .catch(error => {
        alert('A network error prevented fetching slots.')
        throw error;
      });
  }
}
