//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { observe } from 'mobx';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { SparkleanCartEntry } from '../elements/sk-cart-entry';
import { cart as uncastedCart } from '../state';
import { formatPrice, parsePrice } from '../utils';

import { SparkleanCartCoupon } from './components';


const cart = <any>uncastedCart;

function refreshSubtotal(element) {
  // Calculate and update the subtotal also
  let totalContainer = document.querySelector('#main-navigation-menu .cart-total');
  let subtotal = formatPrice(cart.getSubTotal());
  let total = formatPrice((parsePrice(subtotal) - parsePrice(cart.discount)));
  let subtotalElement = element.querySelector('.cart-subtotal') || element.querySelector('.subtotal-container .subtotal-value');
  let totalElement = element.querySelector('.total-container .subtotal-value');
  let couponContainer = element.querySelector('.coupon-container');

  totalContainer.textContent = subtotal;
  subtotalElement.textContent = subtotal;

  if (totalElement) {
    totalElement.textContent = total;
  }

  if (couponContainer) {
    if (cart.coupon) {
      const component = React.createElement(
        SparkleanCartCoupon,
        {
          code: cart.coupon.code
        }
      )

      ReactDOM.render(component, couponContainer);
    } else {
      const component = React.createElement(
        'div',
        {
          className: 'col'
        },
        React.createElement(
          'form',
          {
            id: 'apply-coupon-form',
            className: 'apply-coupon-form',
            onSubmit: e => {
              e.preventDefault();
              const input = <HTMLInputElement>document.querySelector('.apply-coupon-form input');
              cart.addCoupon(input.value);
            },
          },
          React.createElement(
            'div',
            {
              className: 'input-group',
            },
            [
              React.createElement(
                'input',
                {
                  type: 'text',
                  className: 'form-control',
                  name: 'code',
                  placeholder: 'Έχεις κάποιο κουπόνι; Βάλε τον κωδικό εδώ!',
                  ariaLabel: 'Κωδικός κουπονιού',
                  required: true,
                }
              ),
              React.createElement(
                'span',
                {
                  className: 'input-group-btn'
                },
                React.createElement(
                  'button',
                  {
                    className: 'btn btn-bright',
                    type: 'submit',
                  },
                  'Καταχώριση',
                )
              )
            ]
          )
        )
      )

      ReactDOM.render(component, couponContainer);
    }
  }
}

function refreshCartElement(element) {
  let listGroupFlush = element.querySelector('.list-group-flush');

  listGroupFlush.innerHTML = '';

  // If the cart is empty, just add the "empty" placeholder and return.
  if (!cart.entries.length) {
    let emptyContainer = document.createElement('div');
    let emptyText = document.createElement('p');

    emptyContainer.classList.add('list-group-item', 'cart-item', 'empty-cart');
    listGroupFlush.appendChild(emptyContainer);

    emptyText.classList.add('list-group-item-text');
    emptyText.textContent = 'Το καλάθι σου είναι άδειο.';
    emptyContainer.appendChild(emptyText);

    refreshSubtotal(element);
    return;
  }

  cart.entries.forEach((entry) => {
    let entryView = new SparkleanCartEntry();

    entryView.dataset.minQuantity = '1';
    entryView.dataset.pk = entry.pk;
    entryView.dataset.product = entry.product;
    entryView.dataset.productImage = entry.productImage;
    entryView.dataset.productPrice = entry.productPrice;
    entryView.dataset.productTitle = entry.productTitle;
    entryView.dataset.quantity = entry.quantity;
    entryView.dataset.finalPrice = entry.finalPrice;

    listGroupFlush.appendChild(entryView);
  });

  refreshSubtotal(element);
  return;
}

function refreshAllCarts() {
  for (const element of document.body.querySelectorAll('sk-cart')) {
    refreshCartElement(element);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (cart.isClientOnly()) {
    refreshAllCarts();
  }

  // When something changes in the cart, refresh all cart elements.
  observe(cart, (change) => refreshAllCarts());
});
