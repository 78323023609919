//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';


$(() => {
  const form = <HTMLFormElement>document.querySelector('#new-phone-number-form');

  $('#new-phone-number-modal').on('shown.bs.modal', () => {
    $('#new-phone-number').focus();
  });

  $('#new-phone-number-button').on('click', function () {
    // Alert hey!
  });

  $(form).on('submit', function (e) {
    e.preventDefault();
    let number = $('#new-phone-number').val();
    let data = {
      'number': `+30${number}`,
    };

    sk.post(form.action, data).then(function (response) {
      if (response.ok) {
        response.json().then((data) => {
          sessionStorage.setItem('phoneVerificationUuid', data.uuid);
          $('#new-phone-number-modal').modal('hide');
          $('#verify-phone-number-modal').modal('show');
        })
      } else {
        if (response.status < 500 && response.status >= 400) {
          response.json().then((data) => {
            if (data.detail) {
              alert(data.detail)
            } else if (data.number) {
              alert(data.number);
            } else {
              alert(`${response.status}: ${JSON.stringify(data)}`);
            }
          });
        } else {
          alert('Δεν μπορέσαμε να καταχωρίσουμε το τηλέφωνό σας!');
        }
      }
    });
  });

  if (sessionStorage.getItem('openPhoneNumberModal')) {
    sessionStorage.removeItem('openPhoneNumberModal');
    $('#new-phone-number-modal').modal('show');
  }
});
