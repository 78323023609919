//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';


$(() => {
  const form = <HTMLFormElement>document.getElementById('new-address-search-form');
  const $inputElements = $(form).find('input');

  $inputElements.on('keydown', function (e) {
    let $first = $('.search-query-results .list-group-item').first();
    let $last = $('.search-query-results .list-group-item').last();
    let $selected = $('.search-query-results .selected');
    let $next = ($selected.length) ? $selected.next() : $first;
    let $previous = ($selected.length) ? $selected.prev() : $last;
    let $toBeSelected = $first;  // Default

    if (e.keyCode == 40) {  // Down arrow
      $toBeSelected = ($next.length) ? $next : $first;
    } else if (e.keyCode == 38) {  // Up arrow
      $toBeSelected = ($previous.length) ? $previous : $last;
    } else if (e.keyCode == 13) {  // Enter
      if ($selected.length) {
        $selected.click();
      }
    } else {
      return;
    }

    $selected.removeClass('selected');
    $toBeSelected.addClass('selected');
  });

  $inputElements.on('keyup', function (e) {
    const form = <HTMLFormElement>document.getElementById('new-address-search-form');

    if (form.requestQueued) {
      return;
    }

    form.requestQueued = true;

    setTimeout(function () {
      form.requestQueued = false;
      let query = (<HTMLInputElement>document.getElementById('new-address-search-query')).value;

      if (query == form.dataset.query) {
        return;
      } else if (query == '') {
        return;
      }

      form.dataset.query = query;

      let url = `${form.action}?query=${encodeURIComponent(query)}`;

      sk.get(url).then(function (response) {
        response.json().then(function (data) {
          data = data.filter((element) => (element.street_name != ''));
          document.querySelector('.search-query-results').innerHTML = '';

          if (!data.length) {
            return;
          }

          let results = document.createElement('ul');
          results.classList.add('list-group');

          data.forEach((result) => {
            let element = document.createElement('li');

            element.classList.add('list-group-item');
            element.setAttribute('role', 'button');

            Object.keys(result).forEach((key) => {
              let value = result[key] || '';

              if (typeof value == 'object') {
                value = JSON.stringify(value);
              }

              element.dataset[key] = value;
            });

            element.textContent = `${result.street_name} ${result.street_number}, ${result.city} ${result.postal_code}`;

            element.dataset.dismiss = 'modal';
            element.dataset.toggle = 'modal';
            element.dataset.target = '#new-address-modal';

            results.appendChild(element);
          });

          document.querySelector('.search-query-results').appendChild(results);
        });
      });
    }, 100);
  });

  $(form).on('submit', function(e) {
    e.preventDefault();
  });

  $('#new-address-search-modal').on('show.bs.modal', function (ev: any) {
    localStorage.setItem('updateAddressKey', ev.relatedTarget.dataset.key || '');
    // Reset form and any previous results
    form.reset();
    document.querySelector('.search-query-results').innerHTML = '';

    setTimeout(function () {
      $('#new-address-search-form input').first().focus();
    }, 500);
  });
});
