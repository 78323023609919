//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { sk } from '../sk';

import * as utils from '../utils';

export class SparkleanAddressSelector extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    let selector = this;

    setTimeout(() => {
      selector.select.addEventListener('change', () => {
        setTimeout(() => {
          selector.updateAll();
        }, 4);  // Debounce, to allow auto-altering of delivery addres, when using "use same as pickup"
      });
    }, 4);
  }

  get key() {
    return this.dataset.key;
  }

  get select() {
    return this.querySelector('select');
  }

  get value() {
    return this.select.value;
  }

  get url() {
    return this.dataset.url;
  }

  refresh() {
    let self = this;

    sk.get(`${this.url}?key=${this.key}&selected=${this.value}`).then((response) => {
      if (response.ok) {
        response.text().then((text) => {
          self.outerHTML = text;

          if (localStorage.getItem('updateAddressKey') == self.dataset.key) {
            localStorage.removeItem('updateAddressKey');

            let lastAddress = localStorage.getItem('lastAddress');

            if (lastAddress) {
              localStorage.removeItem('lastAddress');

              setTimeout(() => {
                let selector = document.querySelector(`sk-address-selector[data-key="${self.dataset.key}"]`);
                let option = selector.querySelector(`option[value="${lastAddress}"]`);
                option.selected = 'selected';
                self.updateAll();
              }, 4);
            }
          }
        });
      }
    });
  }

  updateAll() {
    let data = {};

    document.querySelectorAll('sk-address-selector').forEach((selector) => {
      data[selector.key] = selector.value;
    });

    // If Delivery Address is not set, then assign the pickup address to it
    data.delivery_address = data.delivery_address || data.pickup_address;

    sk.patch('/api/orders/cart/', data).then((response) => {
      response.json().then((body) => {
        if (!response.ok) {
          const nonFieldErrors = body.non_field_errors || [];

          // If nonFieldErrors exist, then shit happened. Revert both addresses
          // to null.
          document.querySelectorAll('sk-address-selector select option[value=""]').forEach((el) => el.selected = true);

          nonFieldErrors.forEach((error) => {
            utils.displayFlashMessage(error, 'danger');
          });
        } else {
          const pickup_address = body.pickup_address.uuid;
          const delivery_address = body.delivery_address.uuid;

          document.querySelector(`sk-address-selector[data-key="pickup_address"] select option[value="${pickup_address}"]`).selected = true;
          document.querySelector(`sk-address-selector[data-key="delivery_address"] select option[value="${delivery_address}"]`).selected = true;
        }
      });
    });
  }
}

// Define the new element
customElements.define('sk-address-selector', SparkleanAddressSelector);
