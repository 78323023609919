//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';


$(() => {
  const form = <HTMLFormElement>document.querySelector('#verify-phone-number-form');

  $('#verify-phone-number-modal').on('shown.bs.modal', function () {
    form.reset();
    $('#verify-phone-number-form input').removeAttr('disabled');
    $('#pin-digit-1').focus();
  });

  $('.pin-input-group input').on('focus', function (e) {
    $(this).select();
  });

  $('.pin-input-group input').on('keyup', function (e) {
    if (e.keyCode >= 48 && e.keyCode <= 57) {  // This is a number
      let $next = $(this).next();

      if ($next) {
        $next.focus();
      }
    }
  });

  $('.pin-input-group input').on('keyup', function (e) {
    let pin = '';

    $('.pin-input-group input').each(function(i, elem) {
      pin += (<HTMLInputElement>elem).value;
    });

    if (pin.length == 4) {
      $('.pin-input-group input').attr('disabled', 'disabled');
      let data = {
        'verification_pin': pin
      };

      sk.post(`${form.action}${sessionStorage.getItem('phoneVerificationUuid')}/verify/`, data).then(function (response) {
        if (response.ok) {
          alert('Ευχαριστούμε!');
          $('#verify-phone-number-modal').modal('hide');

          response.json().then((data) => {
            let phone = data.number.replace('+30', '');
            $('#phone').val(phone);
          });
        } else {
          alert('Δεν μπορέσαμε να επιβεβαιώσουμε το λογαριασμό σας!');
          form.reset();
          $('.pin-input-group input').removeAttr('disabled');
        }
      });
    }
  });
});
