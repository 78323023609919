//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { cart } from '../state';


export class SparkleanCartEmpty extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = 'Άδειασμα';
    this.setAttribute('role', 'button');
    this.classList.add('btn', 'btn-invisible');
    this.addEventListener('click', function (e) {
      e.stopPropagation();
      cart.updateEntries([]);
    });
  }
}

// Define the new element
customElements.define('sk-cart-empty', SparkleanCartEmpty);
