//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { cart } from '../state';


export class SparkleanCartCouponDelete extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.innerHTML = '<span aria-hidden="true">&times;</span>';
    this.setAttribute('role', 'button');
    this.classList.add('float-left');
    this.addEventListener('click', function (e) {
      e.stopPropagation();
      cart.deleteCoupon();
    });
  }
}

// Define the new element
customElements.define('sk-cart-coupon-delete', SparkleanCartCouponDelete);
