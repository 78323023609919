//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

export class SparkleanOrderList extends HTMLElement {
  constructor() {
    super();
  }

  refresh(callback) {
    let orderListContainer = this;

    sk.get('/components/orders/').then(function (response) {
      return response.text().then(function (text) {
        $(orderListContainer).html(text);

        if (typeof callback == 'function') {
          callback();
        }
      });
    });
  }
}

// Define the new element
customElements.define('sk-order-list', SparkleanOrderList);
