//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import { observe } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';

import * as utils from '../utils';
import { cart } from '../state';

import { SparkleanCartEntryQuantityAdd } from './sk-cart-entry-quantity-add';
import { SparkleanQuantityInput } from './sk-quantity-input';


class SparkleanCatalogEntryCard extends React.Component {

  constructor(props) {
    super(props);

    let self = this;
    self.state = {
      productQuantityInCart: parseInt(props.productQuantityInCart, 10)
    }

    observe(cart, 'entries', () => {
      let newQuantity = cart.getQuantityByProduct(props.product);

      self.setState((prevState, props) => {
        return {
          productQuantityInCart: parseInt(newQuantity, 10)
        }
      });
    });
  }

  render() {
      return React.createElement('div', {
        className: 'card' + ((this.state.productQuantityInCart) ? ' in-cart' : '')
      },
      [
        React.createElement(
          'div',
          {
            className: 'card-header',
            key: `catalog-entry-header-${this.props.product}`
          },
          React.createElement(
            'img',
            {
              src: this.props.productImage,
              alt: this.props.productTitle + ' image'
            },
            null
          )
        ),
        React.createElement(
          'div',
          {
            className: 'card-body',
            key: `catalog-entry-body-${this.props.product}`
          },
          [
            React.createElement(
              'h5',
              {
                key: `cart-product-title-${this.props.product}`,
                className: 'card-title'
              },
              this.props.productTitle
            ),
            React.createElement(
              'p',
              {
                key: `catalog-product-price-${this.props.product}`,
                className: 'card-text product-price'
              },
              this.props.productPrice
            )
          ]
        ),
        React.createElement(
          'div',
          {
            className: 'card-footer',
            key: `catalog-entry-footer-${this.props.product}`
          },
          React.createElement(
            'form',
            {
              className: 'add-to-cart-form'
            },
            React.createElement(
              'div',
              {
                className: 'input-group'
              },
              [
                React.createElement(
                  'span',
                  {
                    className: 'input-group-btn',
                    key: `catalog-product-decrease-quantity-${this.props.product}`
                  },
                  React.createElement(
                    'sk-cart-entry-quantity-add',
                    {
                      'data-product': this.props.product,
                      'data-add-quantity': '-1'
                    }
                  )
                ),
                React.createElement(
                  SparkleanQuantityInput,
                  {
                    key: `catalog-product-quantity-input-${this.props.product}`,
                    product: this.props.product,
                    productTitle: this.props.productTitle,
                    productPrice: this.props.productPrice,
                    quantity: this.state.productQuantityInCart
                  }
                ),
                React.createElement(
                  'span',
                  {
                    className: 'input-group-btn',
                    key: `catalog-product-increase-quantity-${this.props.product}`
                  },
                  React.createElement(
                    'sk-cart-entry-quantity-add',
                    {
                      'data-product': this.props.product,
                      'data-add-quantity': '+1'
                    }
                  )
                )
              ]
            )
          )
        )
      ]);
  }
}


class SparkleanCatalogEntry extends HTMLElement {
  connectedCallback() {  // This runs every time the element gets attached to the DOM
    let self = this;
    let productQuantityInCart = (() => {
      if (cart.isClientOnly()) {
        return cart.getQuantityByProduct(self.dataset.product);
      } else {
        return self.dataset.productQuantityInCart;
      }
    })();

    let component = React.createElement(
      SparkleanCatalogEntryCard,
      {
        product: self.dataset.product,
        productTitle: self.dataset.productTitle,
        productImage: self.dataset.productImage,
        productPrice: self.dataset.productPrice,
        productQuantityInCart: productQuantityInCart
      },
      null
    );

    ReactDOM.render(component, self);
  }
}

// Define the new element
customElements.define('sk-catalog-entry', SparkleanCatalogEntry);
