//  Proprietary and Confidential.
//  Unauthorized copying of this file, via any medium is strictly prohibited.
//
//  Copyright © 2018 SourceLair Private Company. All rights reserved.
//

import * as $ from 'jquery';

import sk from '../sk';


$(() => {
  const form: HTMLFormElement = document.querySelector('#forgot-password-form');

  $('#back-to-sign-in').on('click', () => {
    $('#forgot-password-modal').modal('hide');
    $('#sign-in-modal').modal('show');
  });

  $(form).on('submit', e => {
    e.preventDefault();

    const payload = {
      email: $('#forgot-password-email-address').val()
    };
    const submitButton = $(form).find('button[type="submit"]');

    submitButton.attr('disabled', 'disabled');
    sk.post(form.action, payload)
      .then(response => {
        if (response.ok) {
          alert('Ευχαριστούμε! Ελέγξτε το email σας για το μήνυμα επαναφοράς.')
        } else {
          alert('Oops! Παρουσιάστηκε ένα σφάλμα.')
        }
        submitButton.removeAttr('disabled');
      })
      .catch(() => submitButton.removeAttr('disabled'));
  });

  $('#forgot-password-modal').on('shown.bs.modal', () => {
    setTimeout(() => $('#forgot-password-email-address').focus(), 400);
  });
});
